import React from "react";
import "./projects.css";
import ReactPlayer from "react-player";
import { fadeInDown, fadeIn, fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const FadeInDown = styled.div`
  animation: 3s ${keyframes`${fadeInDown}`};
`;
const FadeInUp = styled.div`
  animation: 2s ${keyframes`${fadeInUp}`};
`;
const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

function Clown() {
  return (
    <div>
      <FadeInDown id="test">
        <Link to={"./../"}>
          <img src={require("./../assets/Symbol.png")} alt="logo" id="symbol" />
        </Link>
      </FadeInDown>

      <div id="container">
        <FadeInDown>
          <h1 className="title" id="header-text">
            ['Clown'/22]
          </h1>
        </FadeInDown>
        <FadeIn>
          <div id="video-container">
            <ReactPlayer
              url="https://youtu.be/ynG3CqQuRsA?si=cpKkQvrtDjHHasrd"
              controls={true}
              light={require("./../assets/clown_banner_image03.jpg")}
            />
          </div>
        </FadeIn>
        <FadeInUp>
          <h2 className="title" id="description-text">
            A miserable clown is unexpectedly invited on an unraveling journey
            that, step by step, brings her back to her true self.
          </h2>
          <h2 className="title" id="description-text">
            Produced by <strong>Infraröd</strong>
          </h2>
        </FadeInUp>
      </div>
    </div>
  );
}

export default Clown;
