import React from "react";
import './home.css'
import { Link } from 'react-router-dom'
import { fadeInDown, fadeIn } from "react-animations";
import styled, {keyframes} from "styled-components";

const FadeInDownFilm = styled.div`animation: 2s ${keyframes `${fadeInDown}`} `
const FadeInDownId = styled.div`animation: 1.5s ${keyframes `${fadeInDown}`} `
const FadeIn = styled.div`animation: 3s ${keyframes `${fadeIn}`}  `

const Home=()=>{
    return(
        <div>
            <FadeIn>
                <Link to={"./"}>
                    <img src={require('./assets/Logo.png')} alt="logo" id="logo"/>
                </Link>
            </FadeIn>
            <div id="body-home">
                <FadeInDownFilm>
                    <Link to={"./Films"} className="links">
                        <h2 className="link-text">[Films]</h2>
                    </Link>
                </FadeInDownFilm>
                <FadeInDownId>
                    <Link to={"./About"} className="links">
                         <h2 className="link-text">[Press me]</h2>
                    </Link>
                </FadeInDownId>
            </div>
        </div>
    )
}

export default Home
