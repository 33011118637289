import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import { Link } from 'react-router-dom'
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa";

function FilmCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        //infinite={false}
        //disableNavIfEdgeVisible={true}
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={3}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#191919',
            color: '#C8C7C6',
            cursor: 'pointer',
            marginLeft: 8,
            marginTop:12,
            border:'none',
            textAlign:'center',
            fontWeight:"bold",
            fontSize:20,
          },
          children: <FaChevronCircleRight />       
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#191919',
            color: '#C8C7C6',
            cursor: 'pointer',
            marginRight: 8,
            border:'none',
            textAlign:'center',
            fontWeight:"bold",
            fontSize:20,
          },
          children:  <FaChevronCircleLeft/>              
        
      }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 1,
            minWidth: 768,

          },
        ]}
        speed={400}
        easing="linear"
      >
        
        <div id='poster'>
            <Link to={"/FS07"}>
                <img src={require('../assets/IRFS07_poster01_affisch.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>
            <Link to={"/FS06"}>
                <img src={require('../assets/IRFS06_poster01_affisch.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>

            <Link to={"/FS05"}>
                <img src={require('../assets/IRFS05_poster01_affisch.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>
            <Link to={"/FS04"}>
                <img src={require('../assets/IRFS04_poster01_affisch_hemsida.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>
            <Link to={"/FS03"}>
                <img src={require('../assets/IRFS03_poster01_affisch.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>
            <Link to={"/FS02"}>
                <img src={require('../assets/IRFS02_poster01_affisch.jpg')} id="banner-1"alt="poster"/>
            </Link>
        </div>
        <div id='poster'>
            <Link to={"/FS01"}>
                <img src={require('../assets/IRFS01_poster01_affisch.jpg')}id="banner-1"alt="poster"/>
            </Link>
        </div>
        
      </ReactSimplyCarousel>
    </div>
  );
}





export default FilmCarousel;