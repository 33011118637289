import React from "react";
import { Link } from "react-router-dom";
import { fadeInDown, fadeIn, fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";
import FilmCarousel from "./components/FilmCarousel";

const FadeInDown = styled.div`
  animation: 3s ${keyframes`${fadeInDown}`};
`;
const FadeInUp = styled.div`
  animation: 2s ${keyframes`${fadeInUp}`};
`;
const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

function Films() {
  return (
    <div>
      <div>
        <Link to={"./.."}>
          <FadeInDown>
            <img src={require("./assets/Symbol.png")} alt="logo" id="symbol" />
          </FadeInDown>
        </Link>
      </div>

      <FadeInDown>
        <h3 id="header-text" className="title-highlight">
          [Feature Shorts]
        </h3>
        <h3 id="description-text" className="title">
          Short stories made by <strong>Olivia Eriksson</strong> in
          collaboration with other{" "}
          <strong>unique & talented film creators.</strong>
        </h3>
      </FadeInDown>

      <FadeIn>
        <FilmCarousel />
      </FadeIn>

      <FadeIn>
        {/*INFRARÖD SHORT FILMS*/}
        <div id="body-films">
          <div id="film-column">
            <FadeInDown>
              <h3 id="header-text" className="title-highlight">
                [Short Films]
              </h3>
              <h3 id="description-text" className="title">
                {" "}
                Longer projects
              </h3>
            </FadeInDown>

            <Link to={"/Clown"} id="link-text">
              <div id="background-plate">
                <img
                  src={require("./assets/clown_banner_image01.jpg")}
                  alt="logo"
                  id="banner-2"
                />
              </div>
              <FadeInUp>
                <h1 id="project-text" className="title">
                  ‘Clown’/22
                </h1>
              </FadeInUp>
            </Link>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default Films;
