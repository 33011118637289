import React from "react";
import "./projects.css";
import { fadeInDown, fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const FadeInDown = styled.div`
  animation: 3s ${keyframes`${fadeInDown}`};
`;
const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

function FS06() {
  return (
    <div>
      <div>
        <Link to={"./../"}>
          <FadeInDown>
            <img
              src={require("./../assets/Symbol.png")}
              alt="logo"
              id="symbol"
            />
          </FadeInDown>
        </Link>
      </div>

      <div id="container">
        <FadeInDown>
          <h1 className="title" id="header-text">
            [Feature Short #6]
          </h1>
        </FadeInDown>
        <FadeIn>
          <h2 className="title" id="description-text">
            An <strong>Infraröd</strong> production starring
            <strong> Elias Sundberg & William Marnfeldt </strong> with VFX by
            <strong> Axel Flovin </strong>
          </h2>
          <h2 className="title" id="description-text-credits">
            [Directed, shot & written by <strong>Olivia Eriksson</strong>]
          </h2>
        </FadeIn>
        <FadeIn>
          <div id="video-container">
            <ReactPlayer
              url="https://youtu.be/y6AqpIRqIzg"
              controls={true}
              light={require("./../assets/FS06_banner_image01.jpg")}
            />
          </div>
          <div id="poster-container">
            <img
              src={require("./../assets/IRFS06_poster01_affisch.jpg")}
              id="poster-image"
              alt="poster"
            />
          </div>
          <h2 className="title" id="description-text">
            [Poster: Olivia Eriksson]
          </h2>
        </FadeIn>
      </div>
    </div>
  );
}

export default FS06;
