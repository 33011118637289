import React from "react";
import "./projects.css";
import { fadeInDown, fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const FadeInDown = styled.div`
  animation: 3s ${keyframes`${fadeInDown}`};
`;
const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

function FS07() {
  return (
    <div>
      <div>
        <Link to={"./../"}>
          <FadeInDown>
            <img
              src={require("./../assets/Symbol.png")}
              alt="logo"
              id="symbol"
            />
          </FadeInDown>
        </Link>
      </div>

      <div id="container">
        <FadeInDown>
          <h1 className="title" id="header-text">
            [Feature Short #7 ]
          </h1>
        </FadeInDown>
        <FadeIn>
          <h2 className="title" id="description-text">
            An <strong>Infraröd</strong> production starring
            <strong> Lina Lundberg & Paulus Roséen </strong>{" "}
          </h2>
          <h2 className="title" id="description-text-credits">
            [Directed, shot & written by <strong>Olivia Eriksson</strong>]
          </h2>{" "}
          <br />
          <h2 className="title" id="description-text-credits">
            {" "}
            Production assistant <strong>Matilda Aldsjö</strong>
          </h2>
          <h2 className="title" id="description-text-credits">
            {" "}
            Extra thanks to <strong>Göran Wide</strong>
          </h2>
        </FadeIn>

        <FadeIn>
          <div id="video-container">
            <ReactPlayer
              url="https://youtu.be/KRTLWYYKbTs"
              controls={true}
              light={require("./../assets/FS07_banner_image01.jpg")}
            />
          </div>
          <div id="poster-container">
            <img
              src={require("./../assets/IRFS07_poster01_affisch.jpg")}
              id="poster-image"
              alt="poster"
            />
          </div>
          <h2 className="title" id="description-text">
            [Poster: Olivia Eriksson]
          </h2>
        </FadeIn>
      </div>
    </div>
  );
}

export default FS07;
