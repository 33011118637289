import React from "react";
import "./projects.css";
import ReactPlayer from "react-player";
import { fadeInDown, fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const FadeInDown = styled.div`
  animation: 3s ${keyframes`${fadeInDown}`};
`;
const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

function FS01() {
  return (
    <div>
      <div>
        <Link to={"./../"}>
          <FadeInDown>
            <img
              src={require("./../assets/Symbol.png")}
              alt="logo"
              id="symbol"
            />
          </FadeInDown>
        </Link>
      </div>

      <div id="container">
        <FadeInDown>
          <h1 className="title" id="header-text">
            [Feature Short #1]
          </h1>
        </FadeInDown>
        <FadeIn>
          <h2 className="title" id="description-text">
            An <strong>Infraröd</strong> production in collaboration with{" "}
            <strong> Simpel Film.</strong>
          </h2>
          <h2 className="title" id="description-text-credits">
            [Directed, shot & written by{" "}
            <strong>Olivia Eriksson & Dan Larsson</strong>]
          </h2>
          <h2 className="title" id="description-text-credits">
            [Skater: <strong>Felix Kantonen</strong>]
          </h2>
        </FadeIn>
        <FadeIn>
          <div id="video-container">
            <ReactPlayer
              url="https://youtu.be/W4uvh228l1E?si=cQj07JoauXg9JoIC"
              controls={true}
              light={require("./../assets/FS01_banner_image02.jpg")}
            />
          </div>

          <div id="poster-container">
            <img
              src={require("./../assets/IRFS01_poster01_affisch.jpg")}
              id="poster-image"
              alt="poster"
            />
          </div>
          <h2 className="title" id="description-text">
            [Poster: Olivia Eriksson]
          </h2>
        </FadeIn>
      </div>
    </div>
  );
}

export default FS01;
