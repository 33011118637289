import "./App.css";
import Home from "./Home";
import About from "./About";
import Films from "./Films";
import FS01 from "./projects/FS01";
import FS02 from "./projects/FS02";
import FS03 from "./projects/FS03";
import FS04 from "./projects/FS04";
import FS05 from "./projects/FS05";
import FS06 from "./projects/FS06";
import FS07 from "./projects/FS07";
import Clown from "./projects/Clown";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/films" element={<Films />} />
        <Route path="/FS01" element={<FS01 />} />
        <Route path="/FS02" element={<FS02 />} />
        <Route path="/FS03" element={<FS03 />} />
        <Route path="/FS04" element={<FS04 />} />
        <Route path="/FS05" element={<FS05 />} />
        <Route path="/FS06" element={<FS06 />} />
        <Route path="/FS07" element={<FS07 />} />
        <Route path="/Clown" element={<Clown />} />
      </Routes>
    </div>
  );
}

export default App;
