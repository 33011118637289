import React  from "react";
import { Link } from 'react-router-dom'
import { fadeInDown, fadeIn } from "react-animations";
import styled, {keyframes} from "styled-components";



const FadeInDown = styled.div`animation: 2s ${keyframes `${fadeInDown}`} `
const FadeIn = styled.div`animation: 2s ${keyframes `${fadeIn}`}  `


function About(){


    return(
        <div>
          <FadeIn>
            <Link to={"./.."}>
                <img src={require('./assets/Symbol.png')} alt="logo" id="symbol"/>
            </Link>
          </FadeIn>
                <div id="body-about" >
                <FadeInDown>
                    <h1 id="header-text" className="title">[Infraröd]</h1>
                </FadeInDown>
                <FadeIn>
                    <h1 className="title" id="description-text">Film creator based in Stockholm, Sweden <br/> - just making film cus I can, & love it.  </h1>
                </FadeIn>
          <FadeIn>
                    <div className="contact-information">
                        <h3 id="description-text" className="title"><strong>Say hello:</strong> olivia@infrarodfilms.com <br/> or just
                        <a id="text" className="title" href="https://www.instagram.com/infrarodfilms/"> send a DM on <span id="text-highlight">Instagram</span> </a>
                        </h3>
                    </div>
          </FadeIn>
                </div>
                <div id="footer">
                    <FadeIn>
                        <a id="text" className="title" href="https://vimeo.com/690441525"> [Press for a good ol' cinematography reel]</a>
                    </FadeIn>
                </div>
        </div>
    )
}

export default About